// =============================
// Imports
// =============================

import { i18n } from '../../../config/i18n';

import { Wrapper, ActualLang, LangList } from './styles';

// =============================
// Component
// =============================

// NOTE: this is a dev only component
const LanguageSwitch = () => (
  <Wrapper>
    <ActualLang>
      Current language:
      {i18n.language}
    </ActualLang>
    <LangList>
      <li>
        <button type="button" onClick={() => i18n.changeLanguage('en')}>
          en
        </button>
      </li>
      <li>
        <button type="button" onClick={() => i18n.changeLanguage('fr')}>
          fr
        </button>
      </li>
      <li>
        <button type="button" onClick={() => i18n.changeLanguage('de')}>
          de
        </button>
      </li>
      <li>
        <button type="button" onClick={() => i18n.changeLanguage('es')}>
          es
        </button>
      </li>
    </LangList>
  </Wrapper>
);

export default LanguageSwitch;
