// =============================
// Imports
// =============================

import { Button, Div, Icons } from '@mewo/components';
import styled, { css } from 'styled-components';

import Link from '../../other/link';

import { headerHeight } from '../../presentationals/header/styles';

// =============================
// Styles
// =============================

export const DragIcon = styled(Icons.Draggable).attrs(() => ({
  fill: '#b2b2b2',
}))`
  left: 1.5rem;
  position: absolute;
  top: 1.5rem;
`;

export const FormTitle = styled(Div)`
  color: #b2b2b2;
  font-size: 1.8rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-bottom: 2rem;
`;

export const FormChildTitle = styled(FormTitle)`
  font-size: 1.4rem;
`;

export const FormGrid = styled(Div)`
  ${({ col }) => {
    if (col === '3') {
      return css`
        align-items: baseline;
        display: flex;
        > * {
          flex-shrink: 0;
          width: calc((100% / 3) - 1.2rem);

          &:not(:last-child) {
            margin-right: auto;
          }
        }
      `;
    }

    if (col === '2') {
      return css`
        align-items: baseline;
        display: flex;
        > * {
          flex-shrink: 0;
          width: calc(50% - 1.2rem);

          &:first-child:not(:last-child) {
            margin-right: auto;
          }
        }
      `;
    }

    return css`
      > * {
        flex-shrink: 0;
        width: calc(100%);

        &:not(:last-child) {
          margin-bottom: 2rem;
        }
      }
    `;
  }};

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const UserGridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 40rem;
  overflow: auto;
  padding-bottom: 2rem;
  width: 100%;
`;

export const LanguageSwitcherWrapper = styled.div`
  position: absolute;
  right: 3rem;
  z-index: 5;
`;

export const DeleteButton = styled.div`
  color: ${({ theme }) => theme.colors.type.error};
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  transition: opacity ${({ theme }) => theme.transition.fast};
  position: absolute;
  right: 3rem;
  top: 3rem;

  &:hover {
    opacity: 0.7;
  }
`;

export const SimpleLink = styled(Link).attrs(() => ({
  textEllipsis: true,
}))`
  text-decoration: none;
`;

export const InputWithText = styled.div`
  display: flex;
  align-items: baseline;
`;

export const InputWithTextRight = styled.div`
  margin-left: 3rem;
`;

export const FormStrong = styled.strong`
  color: ${({ theme }) => theme.colors.type.error};
`;

export const InColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalForm = styled.form`
  width: 100%;
`;

export const FileHelper = styled(Div)`
  font-size: 1.2rem;
  font-style: italic;
  line-height: 1.4;
`;

export const MiscHelper = styled(Div)`
  font-size: 1.2rem;
  font-style: italic;
  line-height: 1.4;
`;

export const Cname = styled.div`
  background-color: #fff6ef;
  border: 0.1rem #ff9999 solid;
  border-radius: 0.5rem;
  display: inline-block;
  font-size: 1.3rem;
  margin-top: 1rem;
  padding: 0.5rem;
`;

export const SettingsBox = styled.div`
  display: flex;
  padding-bottom: 3.2rem;

  &:not(:last-of-type) {
    border-bottom: 0.1rem solid #e5e5e5;
  }

  ${({ theme }) => theme.mediaAbove.sxga`
    &:not(:last-of-type) {
      margin-bottom: 3.2rem;
    }
  `};

  ${({ theme }) => theme.mediaBelow.sxga`
    flex-direction: column;
  `};

  /** We need some extra padding bottom for Modo Menus page (dropdown language of last item creates whitespace) */
  ${({ extraBottomPadding }) => extraBottomPadding
    && css`
      ${({ theme }) => theme.mediaAbove.sxga`
      padding-bottom: 16rem;
    `};
    `};
`;

export const SettingsLeft = styled.div`
  margin-right: 4rem;
  padding-top: 2rem;
  width: 22rem;

  ${({ theme }) => theme.mediaAbove.sxga`
    width: 35rem;
  `};

  ${({ theme }) => theme.mediaBelow.sxga`
    margin-bottom: 3.2rem;
    margin-right: 0;
    width: 100%;
  `};
`;

export const SettingsRight = styled.div`
  margin-left: auto;
  width: 68rem;
  min-width: 68rem;

  ${({ theme }) => theme.mediaAbove.wuxga`
    margin-right: auto;
  `};

  ${({ theme }) => theme.mediaBelow.sxga`
    margin-left: auto;
    width: 100%;
  `};
`;

export const SettingsLabel = styled.div`
  color: #191919;
  font-size: 1.8rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-bottom: 1.5rem;
`;

export const SettingsSubLabel = styled(SettingsLabel)`
  font-size: 1.5rem;
  margin-top: 30px;
  margin-bottom: 1.2rem;
`;

export const SettingsNotice = styled.div`
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  line-height: 1.5rem;

  &:not(:last-child) {
    margin-bottom: 2.4rem;
  }
`;

export const SettingsForm = styled.form`
  background-color: #fff;
  border-radius: 1rem;
  padding: 3rem;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 3.2rem;
  }
`;

/** Use to mimic SettingsForm styles but without being a form element. */
export const SettingsFakeForm = styled(SettingsForm).attrs({
  as: 'div',
})``;

export const SettingsAnchor = styled.div`
  position: relative;
  top: calc(-${headerHeight} - 3.2rem);
`;

export const InputItemBox = styled.div`
  align-items: center;
  display: flex;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const AddItemBtn = styled(Button).attrs(() => ({
  color: 'primary',
  size: 'small',
  width: 'auto',
}))``;

export const DeleteItemButton = styled(Button).attrs({
  color: 'error',
  fillVariant: 'ghost',
  size: 'small',
})`
  margin-left: 2rem;
  position: relative;

  ${({ withoutTopSpacing }) => !withoutTopSpacing && css`
    top: 1rem;
  `};
`;
